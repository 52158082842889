export const FETCH_ALL_LOCATION_REQUEST = "fetch_all_location_request";
export const FETCH_ALL_LOCATION_SUCCESS = "fetch_all_location_success";
export const FETCH_ALL_LOCATION_FAILURE = "fetch_all_location_failure";

export const FETCH_ORG_LOCATION_REQUEST = "fetch_org_location_request";
export const FETCH_ORG_LOCATION_SUCCESS = "fetch_org_location_success";
export const FETCH_ORG_LOCATION_FAILURE = "fetch_org_location_failure";

export const CREATE_LOCATION_REQUEST = "create_location_request";
export const CREATE_LOCATION_SUCCESS = "create_location_success";
export const CREATE_LOCATION_FAILURE = "create_location_failure";

export const ADD_LOCATION_IN_BULK_REQUEST = "add_location_in_bulk_request";
export const ADD_LOCATION_IN_BULK_SUCCESS = "add_location_in_bulk_success";
export const ADD_LOCATION_IN_BULK_FAILURE = "add_location_in_bulk_failure";

export const FETCH_ALL_TIMEZONE_REQUEST = "fetch_all_timezone_request";
export const FETCH_ALL_TIMEZONE_SUCCESS = "fetch_all_timezone_success";
export const FETCH_ALL_TIMEZONE_FAILURE = "fetch_all_timezone_failure";

export const DELETE_LOCATIONS_REQUEST = "delete_locations_request";
export const DELETE_LOCATIONS_SUCCESS = "delete_locations_success";
export const DELETE_LOCATIONS_FAILURE = "delete_locations_failure";

