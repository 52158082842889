export const INVITE_MEMBERS_REQUEST = 'invite_members_request';
export const INVITE_MEMBERS_SUCCESS = 'invite_members_success';
export const INVITE_MEMBERS_FAILURE = 'invite_members_failure';

export const FETCH_PEOPLE_REQUEST = 'fetch_people_request';
export const FETCH_PEOPLE_SUCCESS = 'fetch_people_success';
export const FETCH_PEOPLE_FAILURE = 'fetch_people_failure';

export const MEMBER_DETAILS_FETCH = 'member_details_fetch';
export const MEMBER_DETAILS_SUCCESS = 'member_details_success';
export const MEMBER_DETAILS_FAILURE = 'member_details_failure';

export const UPDATE_MEMBER_ROLE_REQUEST = 'update_member_role_request';
export const UPDATE_MEMBER_ROLE_SUCCESS = 'update_member_role_success';
export const UPDATE_MEMBER_ROLE_FAILURE = 'update_member_role_failure';

export const UPDATE_MEMBER_REQUEST = 'update_member_request';
export const UPDATE_MEMBER_SUCCESS = 'update_member_success';
export const UPDATE_MEMBER_FAILURE = 'update_member_failure';

export const REMOVE_MEMBERSHIP_REQUEST = 'delete_membership_request';
export const REMOVE_MEMBERSHIP_SUCCESS = 'delete_membership_success';
export const REMOVE_MEMBERSHIP_FAILURE = 'delete_membership_failure';

export const REINVITE_MEMBER_REQUEST = 'reinvite_member_request';
export const REINVITE_MEMBER_SUCCESS = 'reinvite_member_success';
export const REINVITE_MEMBER_FAILURE = 'reinvite_member_failure';

export const RESET_MEMBER = 'reset_member';
export const CLEAR_UPDATE_ERROR = 'clear_update_error';

export const successMessages = [
  "Great!! we've saved your input.",
  'Yaay!! data saved successfully.',
  "Success!! we've saved it properly."
];
