import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { closeSnackbar } from '../../../store/ui/actions/mui_snackbar';

const MuiSnackbar = props => {

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.closeSnackbar();
  };

  const { snackbarOpen, snackbarContent } = props;

  if (snackbarOpen) {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleClose}
        message={snackbarContent.message}
        action={
          <React.Fragment>
            { snackbarContent.buttonAction &&
              <Button color="secondary" size="small" onClick={snackbarContent.buttonAction}>
                {snackbarContent.buttonText}
              </Button>
            }
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />
    );
  }
  return <span />;
}

MuiSnackbar.propTypes = {
  snackbarOpen: PropTypes.bool.isRequired,
  closeSnackbar: PropTypes.func.isRequired
};
function mapStateToProps(state) {
  return {
    snackbarOpen: state.ui.snackbarOpen,
    snackbarContent: state.ui.snackbarContent
  };
}
export default connect(mapStateToProps, { closeSnackbar })(MuiSnackbar);
