import {
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
  OPEN_DIALOG,
  CLOSE_DIALOG,
  START_PROGRESS,
  STOP_PROGRESS,
  SET_PAGINATION_AND_SEARCH,
  RESET_PAGINATION
} from '../constants';

const INITIAL_STATE = {
  snackbarOpen: false,
  snackbarContent: null,
  dialogOpen: false,
  dialogContent: null,
  progressIsActive: false,
  page: 0,
  perPage: 10,
  searchTerm: ''
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return {
        ...state,
        snackbarOpen: true,
        snackbarContent: action.payload
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbarOpen: false,
        snackbarContent: null
      };
    case OPEN_DIALOG:
      return {
        ...state,
        dialogOpen: true,
        dialogContent: action.payload
      };
    case CLOSE_DIALOG:
      return {
        ...state,
        dialogOpen: false,
        dialogContent: null
      };
    case START_PROGRESS:
      return {
        ...state,
        progressIsActive: true
      };
    case STOP_PROGRESS:
      return {
        ...state,
        progressIsActive: false
      };
    case SET_PAGINATION_AND_SEARCH:
      return {
        ...state,
        page: action.payload.page,
        perPage: action.payload.per,
        searchTerm: action.payload.searchTerm
      };
    case RESET_PAGINATION:
      return {
        ...state,
        page: 0,
        perPage: 10,
        searchTerm: ''
      };
    default:
      return state;
  }
}
