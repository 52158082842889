import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import { createMuiTheme } from '@material-ui/core/styles';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      ...green,
    },
    secondary: {
      ...orange,
    },
    background: {
      default: '#f9f9f9',
    },
  },
  typography: {
    // fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    useNextVariants: true,
    fontWeight: 400,
  },
  // Overriding default components
  overrides: {
    // select Menu Override
    MuiList:{
      root:{
        overflowX: 'auto'
      }
    },
    // Card component overrides
    MuiButton: {
      root: {
        padding: '8px 24px',
        textTransform: 'none',
        boxShadow: 'none !important',
      },
      label: {
        fontWeight: 600,
      },
    },
    MuiCardHeader: {
      root: {
        // padding: '2px 12px',
        borderBottom: '1px solid #e9e9e9',
      },
      title: {
        color: '#212121',
        fontSize: 16,
        lineHeight: '1.61429em',
      },
      subheader: {
        color: '#757575',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '1.31429em',
      },
      avatar: {
        marginRight: 16,
      },
    },
  },
  // Scrollbar UI
  scrollableY: {
    overflowY: 'auto',
    outlineOffset: '-2px',
    '&:hover': {
      overflowY: 'auto',
    },
    '&::-webkit-scrollbar': {
      width: 5,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#DFDFDF',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f0f0f0',
    },
  },
  lineCard: {
    boxShadow: 'none',
    border: '1px solid #dadce0',
    borderRadius: 8,
  },
  lineCardContent: {
    padding: '20px 30px',
  },
  contentWrapper: {
    flexGrow: 1,
    width: '100%',
    margin: 0,
  },
  authContentWrapper: {
    flexGrow: 1,
    width: '100%',
    margin: 0,
    overflowX: 'hidden',
    overflowY: 'hidden',
    height: '100vh',
  },
  authLeftContainer: {
    padding: '20px 50px 10px 50px',
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100vh',
  },
  authRightContainer: {
    padding: 50,
    overflowX: 'hidden',
    overflowY: 'hidden',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    boxShadow: 'inset 3px 0 10px -7px rgba(0,0,0,.3)',
    backgroundColor: '#f6f6f6',
  },
  singleBreadcrumbContainer: {
    paddingTop: 64,
    paddingBottom: 4,
    borderBottom: '1px solid #dadce0',
    marginBottom: 24,
  },
  breadcrumbFabButton: {
    background: 'transparent',
    boxShadow: 'none',
    color: 'rgba(0, 0, 0, 0.65)',
    fontSize: '1.6rem',
    textTransform: 'none',
    fontWeight: '500',
    paddingLeft: 0,
    '&:hover': {
      background: 'transparent !important',
      boxShadow: 'none',
    },
    '&:focus': {
      background: 'transparent !important',
      boxShadow: 'none',
    },
  },
  breadcrumbFabIcon: {
    color: '#5f6368',
    marginRight: 16,
  },
  actionButton: {
    boxShadow: 'none',
  },
  // Custom for custom components
  card: {
    position: 'relative',
    borderRadius: 8,
    boxShadow: '0 1px 7px rgba(0,0,0,.05)',
    marginBottom: 12,
  },
  cardHeader: {
    padding: '10px 18px',
    border: 'none',
  },
  cardActions: {
    padding: 0,
    borderTop: '1px solid #ecedef',
  },
  cardSingleActionButton: {
    width: '100%',
    justifyContent: 'flex-start',
    padding: '10px 12px 12px 77px',
    fontSize: '0.915rem',
    borderRadius: '0px 0px 8px 8px',
  },
  cardHeaderAvatar: {
    marginRight: 20,
  },
  cardHeaderTitle: {
    color: 'rgba(0,0,0,.66)',
    fontSize: 12,
    marginBottom: 4,
  },
  cardHeaderSubheader: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1rem',
  },
  cardHeaderAction: {
    fontSize: 13,
    color: 'rgba(0,0,0,.56)',
    marginTop: 0,
    marginRight: 0,
  },
});

export default theme;
