import {
  INVITE_COLLABORATORS_REQUEST,
  INVITE_COLLABORATORS_SUCCESS,
  INVITE_COLLABORATORS_FAILURE,
  FETCH_COLLABORATOR_REQUEST,
  FETCH_COLLABORATOR_SUCCESS,
  FETCH_COLLABORATOR_FAILURE,
  UPDATE_COLLABORATOR_ROLE_REQUEST,
  UPDATE_COLLABORATOR_ROLE_SUCCESS,
  UPDATE_COLLABORATOR_ROLE_FAILURE,
  REMOVE_COLLABORATOR_REQUEST,
  REMOVE_COLLABORATOR_SUCCESS,
  REMOVE_COLLABORATOR_FAILURE,
  CLEAR_UPDATE_ERROR,
  RESET_COLLABORATOR
} from '../constants';

const INITIAL_STATE = {
  bulkInvitationSent: null,
  isFetching: false,
  isError: false,
  errorMessage: null,
  errorStatus: null,
  all: [],
  count: 0,
  collaborator: null,
  page: 0,
  perPage: 10,
  invalid_emails: [],
  invited_members: [],
  existing_members: [],
  with_another_role: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case INVITE_COLLABORATORS_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case INVITE_COLLABORATORS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        bulkInvitationSent: true,
        invalid_emails: action.payload.invalid_emails,
        invited_members: action.payload.invited_members,
        existing_members: action.payload.existing_members,
        with_another_role: action.payload.with_another_role
      };
    case INVITE_COLLABORATORS_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    case FETCH_COLLABORATOR_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: ''
      };
    case FETCH_COLLABORATOR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        all: action.payload.data,
        count: action.payload.count
      };
    case FETCH_COLLABORATOR_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    // case MEMBER_DETAILS_FETCH:
    //   return {
    //     ...state,
    //     isFetching: true,
    //     isError: false,
    //     errorMessage: '',
    //     errorStatus: null,
    //     member: null
    //   };
    // case MEMBER_DETAILS_SUCCESS:
    //   return {
    //     ...state,
    //     isFetching: false,
    //     member: action.payload
    //   };
    // case MEMBER_DETAILS_FAILURE:
    //   return {
    //     ...state,
    //     isFetching: false,
    //     isError: true,
    //     errorMessage: action.payload.errorMessage,
    //     errorStatus: action.payload.errorStatus
    //   };
    case UPDATE_COLLABORATOR_ROLE_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: ''
      };
    case UPDATE_COLLABORATOR_ROLE_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case UPDATE_COLLABORATOR_ROLE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    case REMOVE_COLLABORATOR_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: ''
      };
    case REMOVE_COLLABORATOR_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case REMOVE_COLLABORATOR_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    case RESET_COLLABORATOR:
      return {
        ...state
        // peopleList: [],
        // peopleCount: 0,
      };
    case CLEAR_UPDATE_ERROR:
      return {
        ...state,
        isUpdateError: false,
        updateErrorMessage: null,
        isSkillError: false,
        isError: false,
        errorMessage: ''
      };
    default:
      return state;
  }
}
