export const FETCH_CONFERENCES_REQUEST = 'fetch_conferences_request';
export const FETCH_CONFERENCES_SUCCESS = 'fetch_conferences_success';
export const FETCH_CONFERENCES_FAILURE = 'fetch_conferences_failure';

export const FETCH_CONFERENCE_REQUEST = 'fetch_conference_request';
export const FETCH_CONFERENCE_SUCCESS = 'fetch_conference_success';
export const FETCH_CONFERENCE_FAILURE = 'fetch_conference_failure';

export const FETCH_CONDUCTED_CONFERENCE_REQUEST = 'fetch_conducted_conference_request';
export const FETCH_CONDUCTED_CONFERENCE_SUCCESS = 'fetch_conducted_conference_success';
export const FETCH_CONDUCTED_CONFERENCE_FAILURE = 'fetch_conducted_conference_failure';

export const CREATE_CONFERENCE_REQUEST = 'create_conference_request';
export const CREATE_CONFERENCE_SUCCESS = 'create_conference_success';
export const CREATE_CONFERENCE_FAILURE = 'create_conference_failure';

export const UPDATE_CONFERENCE_REQUEST = 'update_conference_request';
export const UPDATE_CONFERENCE_SUCCESS = 'update_conference_success';
export const UPDATE_CONFERENCE_FAILURE = 'update_conference_failure';

export const UPDATE_BULK_CONFERENCE_REQUEST = 'update_bulk_conference_request';
export const UPDATE_BULK_CONFERENCE_SUCCESS = 'update_bulk_conference_success';
export const UPDATE_BULK_CONFERENCE_FAILURE = 'update_bulk_conference_failure';

export const SET_CONF_SELECTED_DATE = 'SET_CONF_SELECTED_DATE';
export const SET_CONF_SELECTED_HOST = 'SET_CONF_SELECTED_HOST';
export const SET_CONF_START_DATE = 'set_conf_start_date';
export const SET_CONF_END_DATE = 'set_conf_end_date';

export const REMIND_CONFERENCE_REQUEST = 'remind_conference_request';
export const REMIND_CONFERENCE_SUCCESS = 'remind_conference_success';
export const REMIND_CONFERENCE_FAILURE = 'remind_conference_failure';

export const CLEAR_SELECTED_CONFERENCE = 'CLEAR_SELECTED_CONFERENCE';

export const FETCH_WEBINARS_REQUEST = 'fetch_webinars_request';
export const FETCH_WEBINARS_SUCCESS = 'fetch_webinars_success';
export const FETCH_WEBINARS_FAILURE = 'fetch_webinars_failure';

export const FETCH_WEBINAR_REQUEST = 'fetch_webinar_request';
export const FETCH_WEBINAR_SUCCESS = 'fetch_webinar_success';
export const FETCH_WEBINAR_FAILURE = 'fetch_webinar_failure';

export const FETCH_CONDUCTED_WEBINAR_REQUEST = 'fetch_conducted_webinar_request';
export const FETCH_CONDUCTED_WEBINAR_SUCCESS = 'fetch_conducted_webinar_success';
export const FETCH_CONDUCTED_WEBINAR_FAILURE = 'fetch_conducted_webinar_failure';

export const CREATE_WEBINAR_REQUEST = 'create_webinar_request';
export const CREATE_WEBINAR_SUCCESS = 'create_webinar_success';
export const CREATE_WEBINAR_FAILURE = 'create_webinar_failure';

export const UPDATE_WEBINAR_REQUEST = 'update_webinar_request';
export const UPDATE_WEBINAR_SUCCESS = 'update_webinar_success';
export const UPDATE_WEBINAR_FAILURE = 'update_webinar_failure';

export const SET_WEBINAR_SELECTED_DATE = 'SET_WEBINAR_SELECTED_DATE';
export const SET_WEBINAR_SELECTED_HOST = 'SET_WEBINAR_SELECTED_HOST';
export const SET_CONF_SELECTED_BATCH = 'set_conf_selected_batch';

export const CLEAR_SELECTED_WEBINAR = 'CLEAR_SELECTED_WEBINAR';
export const SET_CONF_PAGINATION = 'SET_CONF_PAGINATION';

// export const FETCH_CONFERENCE_ATTENDEES_REQUEST = "fetch_conference_attendees_request"
// export const FETCH_CONFERENCE_ATTENDEES_SUCCESS = "fetch_conference_attendees_success"
// export const FETCH_CONFERENCE_ATTENDEES_FAILURE = "fetch_conference_attendees_failure"

// export const FETCH_CONFERENCE_LIST_REQUEST = "fetch_conference_list_request"
// export const FETCH_CONFERENCE_LIST_SUCCESS = "fetch_conference_list_success"
// export const FETCH_CONFERENCE_LIST_FAILURE = "fetch_conference_list_failure"
