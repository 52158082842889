import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

// Relative imports
import * as serviceWorker from './serviceWorker';
// import apocalypseHandler from './apocalypseHandler';
import App from './App';
import reducers from './store/reducers';
import { AUTH_USER } from './store/auth/constants';
import { SW_INIT, SW_UPDATE } from './store/sw/constants';

const store = createStore(reducers, composeWithDevTools(applyMiddleware(reduxThunk)));

// Set session
const token = localStorage.getItem('token');
// If have a token consider the user to stay signed in
if (token) {
  const user = JSON.parse(localStorage.getItem('user'));
  store.dispatch({ type: AUTH_USER, payload: user });
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onSuccess: () => store.dispatch({ type: SW_INIT }),
  onUpdate: reg => store.dispatch({ type: SW_UPDATE, payload: reg.waiting }),
  onWaiting: waiting => store.dispatch({ type: SW_UPDATE, payload: waiting })
});
// apocalypseHandler();
