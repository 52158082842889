import {
  SIGNUP_INVITE_REQUEST,
  SIGNUP_INVITE_SUCCESS,
  SIGNUP_INVITE_FAILURE,
  SIGNUP_INVITE_FORM_RESET,
  SIGNUP_RESEND_OTP_REQUEST,
  SIGNUP_RESEND_OTP_SUCCESS,
  SIGNUP_RESEND_OTP_FAILURE,
  SIGNUP_VERIFY_OTP_REQUEST,
  SIGNUP_VERIFY_OTP_SUCCESS,
  SIGNUP_VERIFY_OTP_FAILURE,
  SIGNUP_SET_PASSWORD_REQUEST,
  SIGNUP_SET_PASSWORD_FAILURE
  // SIGNUP_KEY_FETCH_REQUEST,
  // SIGNUP_KEY_FETCH_SUCCESS,
  // SIGNUP_KEY_FETCH_FAILURE,
  // SIGNUP_GENERATE_OTP_REQUEST,
  // SIGNUP_GENERATE_OTP_SUCCESS,
  // SIGNUP_GENERATE_OTP_FAILURE,
  // QUICK_SIGNUP_REQUEST,
  // QUICK_SIGNUP_FAILURE
} from '../constants';

const INITIAL_STATE = {
  otpGenerated: false,
  otpResent: false,
  otpVerified: false,
  isError: false,
  isFetching: false,
  errorMessage: '',
  errorFields: {},
  organisation: {},
  user: {}
  // signupKeyData: null,
  // isFetchingKey: false,
  // isKeyError: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SIGNUP_INVITE_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: '',
        organisation: {},
        user: {}
      };
    case SIGNUP_INVITE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        otpGenerated: true,
        organisation: action.payload.organisation,
        user: action.payload.user
      };
    case SIGNUP_INVITE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload.errorMessage,
        errorFields: action.payload.errorFields,
        organisation: {},
        user: {}
      };
    case SIGNUP_INVITE_FORM_RESET:
      return {
        ...state,
        otpGenerated: false,
        isFetching: false,
        isError: false,
        errorMessage: '',
        errorFields: {},
        organisation: {},
        user: {}
      };
    case SIGNUP_RESEND_OTP_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: ''
      };
    case SIGNUP_RESEND_OTP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        errorMessage: '',
        user: action.payload.data.user
      };
    case SIGNUP_RESEND_OTP_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    case SIGNUP_VERIFY_OTP_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false
      };
    case SIGNUP_VERIFY_OTP_SUCCESS:
      return {
        ...state,
        otpVerified: true,
        isFetching: false,
        isError: false,
        errorMessage: '',
        user: action.payload.data.user
      };
    case SIGNUP_VERIFY_OTP_FAILURE:
      return {
        ...state,
        otpVerified: false,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    case SIGNUP_SET_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false
      };
    case SIGNUP_SET_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    // case SIGNUP_KEY_FETCH_REQUEST:
    //   return {
    //     ...state,
    //     isFetchingKey: true,
    //     isKeyError: false
    //   };
    // case SIGNUP_KEY_FETCH_SUCCESS:
    //   return {
    //     ...state,
    //     signupKeyData: action.payload.data,
    //     isFetchingKey: false,
    //     isKeyError: false,
    //     errorMessage: ''
    //   };
    // case SIGNUP_KEY_FETCH_FAILURE:
    //   return {
    //     ...state,
    //     signupKeyData: null,
    //     isFetchingKey: false,
    //     isKeyError: true,
    //     errorMessage: action.payload
    //   };
    // case SIGNUP_GENERATE_OTP_REQUEST:
    //   return {
    //     ...state,
    //     otpGenerated: false,
    //     user: {},
    //     isFetching: true,
    //     isError: false
    //   };
    // case SIGNUP_GENERATE_OTP_SUCCESS:
    //   return {
    //     ...state,
    //     otpGenerated: true,
    //     isFetching: false,
    //     isError: false,
    //     errorMessage: '',
    //     user: action.payload.data.user
    //   };
    // case SIGNUP_GENERATE_OTP_FAILURE:
    //   return {
    //     ...state,
    //     otpGenerated: false,
    //     isFetching: false,
    //     isError: true,
    //     user: {},
    //     errorMessage: action.payload
    //   };
    // case QUICK_SIGNUP_REQUEST:
    //   return {
    //     ...state,
    //     isFetching: true,
    //     isError: false
    //   };
    // case QUICK_SIGNUP_FAILURE:
    //   return {
    //     ...state,
    //     isFetching: false,
    //     isError: true,
    //     user: {},
    //     errorMessage: action.payload
    //   };
    default:
      return state;
  }
}
