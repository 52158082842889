import {
  FETCH_ALL_LOCATION_REQUEST,
  FETCH_ALL_LOCATION_SUCCESS,
  FETCH_ALL_LOCATION_FAILURE,
  CREATE_LOCATION_REQUEST,
  CREATE_LOCATION_SUCCESS,
  CREATE_LOCATION_FAILURE,
  ADD_LOCATION_IN_BULK_REQUEST,
  ADD_LOCATION_IN_BULK_SUCCESS,
  ADD_LOCATION_IN_BULK_FAILURE,
  FETCH_ALL_TIMEZONE_REQUEST,
  FETCH_ALL_TIMEZONE_SUCCESS,
  FETCH_ALL_TIMEZONE_FAILURE,
  FETCH_ORG_LOCATION_REQUEST,
  FETCH_ORG_LOCATION_SUCCESS,
  FETCH_ORG_LOCATION_FAILURE,
  DELETE_LOCATIONS_REQUEST,
  DELETE_LOCATIONS_SUCCESS,
  DELETE_LOCATIONS_FAILURE
} from '../constants';

const INITIAL_STATE = {
  isFetching: false,
  isError: false,
  errorMessage: '',
  errorFields: {},
  locations: [],
  orgLocations: [],
  timezones: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_LOCATION_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: ''
      };
    case FETCH_ALL_LOCATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        errorMessage: '',
        locations: action.payload
      };
    case FETCH_ALL_LOCATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload,
        locations: []
      };
    case CREATE_LOCATION_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: '',
        errorFields: {}
      };
    case CREATE_LOCATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        errorMessage: '',
        orgLocations: action.payload
      };
    case CREATE_LOCATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload.errorMessage,
        errorFields: action.payload.errorFields
      };
    case ADD_LOCATION_IN_BULK_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: '',
        errorFields: {}
      };
    case ADD_LOCATION_IN_BULK_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        errorMessage: '',
        orgLocations: action.payload
      };
    case ADD_LOCATION_IN_BULK_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload.errorMessage,
        errorFields: action.payload.errorFields
      };
    case FETCH_ALL_TIMEZONE_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: ''
      };
    case FETCH_ALL_TIMEZONE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        errorMessage: '',
        timezones: action.payload
      };
    case FETCH_ALL_TIMEZONE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    case FETCH_ORG_LOCATION_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: '',
        orgLocations: []
      };
    case FETCH_ORG_LOCATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        orgLocations: action.payload
      };
    case FETCH_ORG_LOCATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    case DELETE_LOCATIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: ''
      };
    case DELETE_LOCATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        orgLocations: action.payload
      };
    case DELETE_LOCATIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};
