import React, { lazy } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import withSuspense from './routes/shared/hoc/withSuspense';

// Relative imports
import withRoot from './routes/shared/hoc/withRoot';
import MuiComponents from './routes/shared/components/MuiComponents';

// Lazy loading components
const Signin = lazy(() => import(/* webpackChunkName: "Signin" */ './routes/signin'));
const Signup = lazy(() => import(/* webpackChunkName: "Signup" */ './routes/signup'));

const ForgotPassword = lazy(() => import(/* webpackChunkName: "ForgotPassword" */ './routes/forgot_password'));
const AcceptInvitation = lazy(() => import(/* webpackChunkName: "AcceptInvitation" */ './routes/accept_invitation'));
const ActivateMembership = lazy(() =>
  import(/* webpackChunkName: "ActivateMembership" */ './routes/activate_membership')
);
const Signout = lazy(() => import(/* webpackChunkName: "Signout" */ './routes/signout'));
const Memberships = lazy(() => import(/* webpackChunkName: "Memberships" */ './routes/memberships'));
const Admin = lazy(() => import(/* webpackChunkName: "Admin" */ './routes/admin'));
const Onboarding = lazy(() => import(/* webpackChunkName: "Onboarding" */ './routes/onboarding'));
// const OnboardingAdmin = lazy(() => import(/* webpackChunkName: "Admin Onboarding" */ './routes/onboarding_admin'));
const PublicWebinars = lazy(() => import(/* webpackChunkName: "PublicWebinars" */ './routes/public_webinars'));
// Protect private routes
const PrivateRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route
    {...rest}
    render={props => (authenticated ? <Component {...props} /> : <Redirect to={{ pathname: '/signin' }} />)}
  />
);
PrivateRoute.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired
};

// Redirect public routes
const PublicRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route
    {...rest}
    render={props => (!authenticated ? <Component {...props} /> : <Redirect to={{ pathname: '/admin/dashboard' }} />)}
  />
);
PublicRoute.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired
};

const App = props => {
  const { authenticated, isServiceWorkerUpdated, serviceWorkerRegistration } = props;

  if (isServiceWorkerUpdated) {
    console.log('SW: There is a new version available, auto updating app');
    const registrationWaiting = serviceWorkerRegistration;
    if (registrationWaiting) {
      console.log('SW: SKIP_WAITING');
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      console.log('SW: SKIP_WAITING Called');
      registrationWaiting.addEventListener('statechange', e => {
        console.log('SW: statechange');
        if (e.target.state === 'activated') {
          console.log('SW: activated');
          window.location.reload();
        }
      });
    }
  }

  return (
    <div>
      <MuiComponents />
      <Router>
        <>
          <PublicRoute exact path="/" component={withSuspense(Signin)} authenticated={authenticated} />
          <PublicRoute path="/signin" component={withSuspense(Signin)} authenticated={authenticated} />
          <PublicRoute path="/signup" component={withSuspense(Signup)} authenticated={authenticated} />
          <PublicRoute path="/forgot-password" component={withSuspense(ForgotPassword)} authenticated={authenticated} />
          <PublicRoute
            path="/accept_invitation/:token"
            component={withSuspense(AcceptInvitation)}
            authenticated={authenticated}
          />
          <Route path="/meetings" component={withSuspense(PublicWebinars)} />
          <Route path="/webinars" component={withSuspense(PublicWebinars)} />
          <Route path="/activate_membership/:token" component={withSuspense(ActivateMembership)} />
          <Route path="/signout" component={withSuspense(Signout)} />
          <PrivateRoute path="/onboarding" component={withSuspense(Onboarding)} authenticated={authenticated} />
          {/* <PrivateRoute
            path="/activate_account"
            component={withSuspense(OnboardingAdmin)}
            authenticated={authenticated}
          /> */}
          <PrivateRoute path="/admin" component={withSuspense(Admin)} authenticated={authenticated} />
          <PrivateRoute path="/memberships" component={withSuspense(Memberships)} authenticated={authenticated} />
        </>
      </Router>
    </div>
  );
};

App.propTypes = {
  authenticated: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    isServiceWorkerUpdated: state.sw.serviceWorkerUpdated,
    serviceWorkerRegistration: state.sw.serviceWorkerRegistration
  };
}
export default connect(mapStateToProps, null)(withRoot(App));
