export const AUTH_USER = 'auth_user';
export const UNAUTH_USER = 'unauth_user';
export const AUTH_USER_REQUEST = 'auth_user_request';
export const AUTH_USER_SUCCESS = 'auth_user_success';
export const AUTH_USER_FAILURE = 'auth_user_failure';
export const SIGNIN_USER_FORM_RESET = 'signin_user_form_reset';
export const SIGNUP_INVITE_REQUEST = 'SIGNUP_INVITE_REQUEST';
export const SIGNUP_INVITE_SUCCESS = 'SIGNUP_INVITE_SUCCESS';
export const SIGNUP_INVITE_FAILURE = 'SIGNUP_INVITE_FAILURE';
export const SIGNUP_INVITE_FORM_RESET = 'SIGNUP_INVITE_FORM_RESET';
export const SIGNUP_RESEND_OTP_REQUEST = 'signup_resend_otp_request';
export const SIGNUP_RESEND_OTP_SUCCESS = 'signup_resend_otp_success';
export const SIGNUP_RESEND_OTP_FAILURE = 'signup_resend_otp_failure';
export const SIGNUP_VERIFY_OTP_REQUEST = 'signup_verify_otp_request';
export const SIGNUP_VERIFY_OTP_SUCCESS = 'signup_verify_otp_success';
export const SIGNUP_VERIFY_OTP_FAILURE = 'signup_verify_otp_failure';
export const SIGNUP_SET_PASSWORD_REQUEST = 'signup_set_password_request';
export const SIGNUP_SET_PASSWORD_FAILURE = 'signup_set_password_failure';
export const ACCEPT_INVITATION_REQUEST = 'accept_invitation_request';
export const ACCEPT_INVITATION_SUCCESS = 'accept_invitation_success';
export const ACCEPT_INVITATION_FAILURE = 'accept_invitation_failure';
export const ACTIVATE_MEMBERSHIP_REQUEST = 'activate_membership_request';
export const ACTIVATE_MEMBERSHIP_FAILURE = 'activate_membership_failure';
export const FORGOT_PASSWORD_FORM_RESET = 'forgot_password_form_reset';
export const FORGOT_PASSWORD_GENERATE_OTP_REQUEST = 'forgot_password_generate_otp_request';
export const FORGOT_PASSWORD_GENERATE_OTP_SUCCESS = 'forgot_password_generate_otp_success';
export const FORGOT_PASSWORD_GENERATE_OTP_FAILURE = 'forgot_password_generate_otp_failure';
export const FORGOT_PASSWORD_RESEND_OTP_REQUEST = 'forgot_password_resend_otp_request';
export const FORGOT_PASSWORD_RESEND_OTP_SUCCESS = 'forgot_password_resend_otp_success';
export const FORGOT_PASSWORD_RESEND_OTP_FAILURE = 'forgot_password_resend_otp_failure';
export const FORGOT_PASSWORD_VERIFY_OTP_REQUEST = 'forgot_password_verify_otp_request';
export const FORGOT_PASSWORD_VERIFY_OTP_SUCCESS = 'forgot_password_verify_otp_success';
export const FORGOT_PASSWORD_VERIFY_OTP_FAILURE = 'forgot_password_verify_otp_failure';
export const FORGOT_PASSWORD_SET_PASSWORD_REQUEST = 'forgot_password_set_password_request';
export const FORGOT_PASSWORD_SET_PASSWORD_FAILURE = 'forgot_password_set_password_failure';
export const INVITE_USER_FORM_RESET = 'invite_user_form_reset';

//NEW CONSTANTS
// export const SIGNUP_KEY_FETCH_REQUEST = 'signup_key_fetch_request';
// export const SIGNUP_KEY_FETCH_SUCCESS = 'signup_key_fetch_success';
// export const SIGNUP_KEY_FETCH_FAILURE = 'signup_key_fetch_failure';
// export const SIGNUP_GENERATE_OTP_REQUEST = 'signup_generate_otp_request';
// export const SIGNUP_GENERATE_OTP_SUCCESS = 'signup_generate_otp_success';
// export const SIGNUP_GENERATE_OTP_FAILURE = 'signup_generate_otp_failure';
// export const QUICK_SIGNUP_REQUEST = 'QUICK_SIGNUP_REQUEST';
// export const QUICK_SIGNUP_FAILURE = 'QUICK_SIGNUP_FAILURE';
