import {
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR
} from '../constants';


export function openSnackbar(options = {}) {
  return {
    type: OPEN_SNACKBAR,
    payload: options
  };
}

export function closeSnackbar(options = {}) {
  return {
    type: CLOSE_SNACKBAR
  };
}
