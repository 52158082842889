export const FETCH_ATTENDENCE_REQUEST = "fetch_attendence_request";
export const FETCH_ATTENDENCE_SUCCESS = "fetch_attendence_success";
export const FETCH_ATTENDENCE_FAILURE = "fetch_attendence_failure";

export const FETCH_ACTIVITY_REQUEST = "fetch_activity_request";
export const FETCH_ACTIVITY_SUCCESS = "fetch_activity_success";
export const FETCH_ACTIVITY_FAILURE = "fetch_activity_failure";

export const RESET_PASSWORD_REQUEST = 'reset_password_request';
export const RESET_PASSWORD_FAILURE = 'reset_password_failure';
export const RESET_PASSWORD_FORM    = 'reset_password_form';