export const FETCH_COURSES_REQUEST = 'fetch_courses_request';
export const FETCH_COURSES_SUCCESS = 'fetch_courses_success';
export const FETCH_COURSES_FAILURE = 'fetch_courses_failure';

export const FETCH_COURSE_REQUEST = 'fetch_course_request';
export const FETCH_COURSE_SUCCESS = 'fetch_course_success';
export const FETCH_COURSE_FAILURE = 'fetch_course_failure';

export const CREATE_COURSE_REQUEST = 'create_course_request';
export const CREATE_COURSE_SUCCESS = 'create_course_success';
export const CREATE_COURSE_FAILURE = 'create_course_failure';

export const FETCH_BATCH_REQUEST = 'fetch_batch_request';
export const FETCH_BATCH_SUCCESS = 'fetch_batch_success';
export const FETCH_BATCH_FAILURE = 'fetch_batch_failure';

export const FETCH_BATCH_SIGNUP_KEY_REQUEST = 'fetch_batch_signup_key_request';
export const FETCH_BATCH_SIGNUP_KEY_SUCCESS = 'fetch_batch_signup_key_success';
export const FETCH_BATCH_SIGNUP_KEY_FAILURE = 'fetch_batch_signup_key_failure';


export const UPDATE_BATCH_SIGNUP_KEY_REQUEST = 'fetch_batch_signup_key_request';
export const UPDATE_BATCH_SIGNUP_KEY_SUCCESS = 'fetch_batch_signup_key_success';
export const UPDATE_BATCH_SIGNUP_KEY_FAILURE = 'fetch_batch_signup_key_failure';

export const CREATE_BATCH_REQUEST = 'create_batch_request';
export const CREATE_BATCH_SUCCESS = 'create_batch_success';
export const CREATE_BATCH_FAILURE = 'create_batch_failure';

export const UPDATE_BATCH_REQUEST = 'update_batch_request';
export const UPDATE_BATCH_SUCCESS = 'update_batch_success';
export const UPDATE_BATCH_FAILURE = 'update_batch_failure';

export const UPDATE_COURSE_REQUEST = 'update_course_request';
export const UPDATE_COURSE_SUCCESS = 'update_course_success';
export const UPDATE_COURSE_FAILURE = 'update_course_failure';

export const SEND_MAIL_REQUEST = 'send_mail_request';
export const SEND_MAIL_SUCCESS = 'send_mail_success';
export const SEND_MAIL_FAILURE = 'send_mail_failure';


export const CLEAR_SELECTED_BATCH = 'clear_selected_batch';

export const successMessages = [
  "Great!! we've saved your input.",
  'Yaay!! data saved successfully.',
  "Success!! we've saved it properly."
];
