export const OPEN_SNACKBAR = 'open_snackbar';
export const CLOSE_SNACKBAR = 'close_snackbar';
export const OPEN_DIALOG = 'open_dialog';
export const CLOSE_DIALOG = 'close_dialog';
export const START_PROGRESS = 'start_progress';
export const STOP_PROGRESS = 'stop_progress';
export const SET_PAGINATION_AND_SEARCH = "set_pagination_and_search";
export const RESET_PAGINATION = "reset_pagination";
export const SET_ONBOARDING_NAVLINKS = "SET_ONBOARDING_NAVLINKS";
export const SET_ADMIN_NAVLINKS = "SET_ADMIN_NAVLINKS";
