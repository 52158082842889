import {
  ORGANISATION_BASIC_INFO_UPDATE_REQUEST,
  ORGANISATION_BASIC_INFO_UPDATE_SUCCESS,
  ORGANISATION_BASIC_INFO_UPDATE_FAILURE,
  ORGANISATION_FETCH_REQUEST,
  ORGANISATION_FETCH_SUCCESS,
  ORGANISATION_FETCH_FAILURE
} from '../constants';

const INITIAL_STATE = {
  organisation: null,
  isFetching: false,
  isError: false,
  errorMessage: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ORGANISATION_BASIC_INFO_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case ORGANISATION_BASIC_INFO_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        organisation: action.payload.data
      };
    case ORGANISATION_BASIC_INFO_UPDATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    case ORGANISATION_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: null
      };
    case ORGANISATION_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        organisation: action.payload
      };
    case ORGANISATION_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    default:
      return state;
  }
}
