import { Satellite } from '@material-ui/icons';
import {
  FETCH_BATCH_REQUEST,
  FETCH_BATCH_SUCCESS,
  FETCH_BATCH_FAILURE,
  CREATE_BATCH_REQUEST,
  CREATE_BATCH_SUCCESS,
  CREATE_BATCH_FAILURE,
  UPDATE_BATCH_REQUEST,
  UPDATE_BATCH_SUCCESS,
  UPDATE_BATCH_FAILURE,
  CLEAR_SELECTED_BATCH,
  FETCH_BATCH_SIGNUP_KEY_REQUEST,
  FETCH_BATCH_SIGNUP_KEY_SUCCESS,
  FETCH_BATCH_SIGNUP_KEY_FAILURE,
  UPDATE_BATCH_SIGNUP_KEY_REQUEST,
  UPDATE_BATCH_SIGNUP_KEY_SUCCESS,
  UPDATE_BATCH_SIGNUP_KEY_FAILURE,
  SEND_MAIL_REQUEST,
  SEND_MAIL_SUCCESS,
} from '../constants';

const INITIAL_STATE = {
  isFetching: false,
  isMailsending: false,
  isError: false,
  errorMessage: '',
  errorFields: {},
  selected: {},
  members: [],
  isFetchingKey: false,
  signupKey: null,
  keyId: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_BATCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: ''
      };
    case FETCH_BATCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        selected: action.payload.batch,
        members: action.payload.members
      };
    case FETCH_BATCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: false,
        errorMessage: action.payload
      };
    case CREATE_BATCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: '',
        errorFields: {}
      };
    case CREATE_BATCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        selectedBatch: action.payload
      };
    case CREATE_BATCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload.errorMessage,
        errorFields: action.payload.errorFields
      };
    case UPDATE_BATCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: '',
        errorFields: {}
      };
    case UPDATE_BATCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        selectedBatch: action.payload
      };
    case UPDATE_BATCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload.errorMessage,
        errorFields: action.payload.errorFields
      };
    case CLEAR_SELECTED_BATCH:
      return {
        ...state,
        selected: action.payload,
        members: action.payload
      };
    case FETCH_BATCH_SIGNUP_KEY_REQUEST:
      return {
        ...state,
        isFetchingKey: true,
        signupKey: null
      };
    case FETCH_BATCH_SIGNUP_KEY_SUCCESS:
      return {
        ...state,
        isFetchingKey: false,
        signupKey: action.payload
      };
    case FETCH_BATCH_SIGNUP_KEY_FAILURE:
      return {
        ...state,
        isFetchingKey: false
      };
    case UPDATE_BATCH_SIGNUP_KEY_REQUEST:
      return {
        ...state,
        isFetchingKey: true,
        signupKey: null
      };
    case UPDATE_BATCH_SIGNUP_KEY_SUCCESS:
      return {
        ...state,
        isFetchingKey: false,
        signupKey: action.payload
      };
    case UPDATE_BATCH_SIGNUP_KEY_FAILURE:
      return {
        ...state,
        isFetchingKey: false
      };

    case SEND_MAIL_REQUEST:
      return{
      ...state,
      isMailsending: true
    };
    case SEND_MAIL_SUCCESS:
      return{
      ...state,
      isMailsending: false
    };
    default:
      return state;
  }
};
