import {
  FETCH_WEBINARS_REQUEST,
  FETCH_WEBINARS_SUCCESS,
  FETCH_WEBINARS_FAILURE,
  FETCH_WEBINAR_REQUEST,
  FETCH_WEBINAR_SUCCESS,
  FETCH_WEBINAR_FAILURE,
  FETCH_CONDUCTED_WEBINAR_SUCCESS,
  CREATE_WEBINAR_REQUEST,
  CREATE_WEBINAR_SUCCESS,
  CREATE_WEBINAR_FAILURE,
  UPDATE_WEBINAR_REQUEST,
  UPDATE_WEBINAR_SUCCESS,
  UPDATE_WEBINAR_FAILURE,
  SET_WEBINAR_SELECTED_DATE,
  SET_WEBINAR_SELECTED_HOST,
  CLEAR_SELECTED_CONFERENCE
} from '../constants';

const INITIAL_STATE = {
  isFetching: false,
  weekdays: [],
  ongoing: [],
  upcoming: [],
  conducted: [],
  conducted_count: 0,
  selected: {},
  selectedDate: null,
  selectedHost: null,
  conductedSelected: {},
  isError: false,
  errorMessage: '',
  errorFields: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_WEBINARS_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: '',
        errorFields: {}
      };
    case FETCH_WEBINARS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        weekdays: action.payload.weekdays,
        ongoing: action.payload.ongoing,
        upcoming: action.payload.upcoming,
        conducted: action.payload.conducted,
        conducted_count: action.payload.conducted_count
      };
    case FETCH_WEBINARS_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload.errorMessage
      };
    case FETCH_WEBINAR_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: ''
      };
    case FETCH_WEBINAR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        selected: action.payload
      };
    case FETCH_WEBINAR_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload.errorMessage
      };
    case FETCH_CONDUCTED_WEBINAR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        conductedSelected: action.payload
      };
    case CREATE_WEBINAR_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: '',
        errorFields: {}
      };
    case CREATE_WEBINAR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        selected: action.payload
      };
    case CREATE_WEBINAR_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload.errorMessage,
        errorFields: action.payload.errorFields
      };
    case UPDATE_WEBINAR_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: '',
        errorFields: {}
      };
    case UPDATE_WEBINAR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        selected: {},
        ongoing: action.payload.ongoing,
        upcoming: action.payload.upcoming
      };
    case UPDATE_WEBINAR_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    case SET_WEBINAR_SELECTED_HOST:
      return {
        ...state,
        selectedHost: action.payload
      };
    case SET_WEBINAR_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.payload
      };
    case CLEAR_SELECTED_CONFERENCE:
      return {
        ...state,
        selected: action.payload
      };
    default:
      return state;
  }
};
