import React, { Fragment } from 'react';
// Relative imports
import MuiSnackbar from '../containers/MuiSnackbar';
// import MuiDialog from '../containers/MuiDialog';
import MuiProgress from '../containers/MuiProgress';

const MuiComponents = () => {
  return (
    <Fragment>
      <MuiSnackbar />
      {/*<MuiDialog />*/}
      <MuiProgress />
    </Fragment>
  );
}

export default MuiComponents;
