import {
  ORG_SUMMARY_FETCH_REQUEST,
  ORG_SUMMARY_FETCH_SUCCESS,
  ORG_SUMMARY_FETCH_FAILURE,
  DASHBOARD_FEEDS_FETCH_REQUEST,
  DASHBOARD_FEEDS_FETCH_SUCCESS,
  DASHBOARD_FEEDS_FETCH_FAILURE,
  CLEAR_CONFERENCE_DATA
} from "../constants";

const INITIAL_STATE = {
  isFetching: false,
  isError: false,
  errorMessage: "",
  errorFields: {},
  org_summary: null,
  summary: {},
  ongoingConferences: [],
  upcomingConferences: [],
  ongoingMeetings: [],
  upcomingMeetings: []

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ORG_SUMMARY_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: ""
      };
    case ORG_SUMMARY_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        errorMessage: "",
        org_summary: action.payload
      };
    case ORG_SUMMARY_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload,
        org_summary: null
      };
    case DASHBOARD_FEEDS_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: ""
      };
    case DASHBOARD_FEEDS_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        errorMessage: "",
        summary: action.payload.summary,
        ongoingConferences: action.payload.ongoing_conferences,
        upcomingConferences: action.payload.upcoming_conferences,
        ongoingMeetings: action.payload.ongoing_meeting,
        upcomingMeetings: action.payload.upcoming_meeting,
      };
    case DASHBOARD_FEEDS_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload,
        ongoingConferences: [],
        upcomingConferences: []
      };
    case CLEAR_CONFERENCE_DATA:
      return {
        ...state,
        ongoingConferences: [],
        upcomingConferences: [],
        ongoingMeetings: [],
        upcomingMeetings: []
      }
    default:
      return state;
  }
};
