import {
  IMAGE_UPDATE_REQUEST,
  IMAGE_UPDATE_SUCCESS,
  IMAGE_UPDATE_FAILURE,
  USER_FETCH_REQUEST,
  USER_FETCH_SUCCESS,
  USER_FETCH_FAILURE,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  RESET_CHANGE_PASSWORD_FORM,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_FAILURE
} from '../constants';

const INITIAL_STATE = {
  user: {},
  isFetching: false,
  isError: false,
  errorMessage: null,
  UploadImageError: false,
  isUpdateError: false,
  updateErrorMessage: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {


    case USER_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case USER_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.payload,
      };
    case USER_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };

    case USER_UPDATE_REQUEST:
      return {
        ...state,
        isUpdateError: false,
        updateErrorMessage: null
      };
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        user: action.payload
      };

    case USER_UPDATE_FAILURE:
      return {
        ...state,
        isUpdateError: true,
        updateErrorMessage: action.payload
      };

    case IMAGE_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true,
        UploadImageError: false,
        errorMessage: null

      };
    case IMAGE_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.payload.data,
        errorMessage: null,
        UploadImageError: false
      };
    case IMAGE_UPDATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        UploadImageError: true,
        errorMessage: action.payload
      };
    case RESET_CHANGE_PASSWORD_FORM:
      return {
        ...state,
        isError: false,
        errorMessage: null,
      };      
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: null,
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };   
    default:
      return state;
  }
}
