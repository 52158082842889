import {
  AUTH_USER,
  UNAUTH_USER,
  AUTH_USER_REQUEST,
  AUTH_USER_SUCCESS,
  AUTH_USER_FAILURE,
  ACTIVATE_MEMBERSHIP_REQUEST,
  ACTIVATE_MEMBERSHIP_FAILURE,
  SIGNIN_USER_FORM_RESET,
  // FORGOT_PASSWORD_FORM_RESET,
  // FORGOT_PASSWORD_REQUEST,
  // FORGOT_PASSWORD_SUCCESS,
  // FORGOT_PASSWORD_FAILURE,
  // RESET_PASSWORD_REQUEST,
  // RESET_PASSWORD_FAILURE
} from '../constants';

const INITIAL_STATE = {
  authenticated: false,
  isFetching: false,
  isError: false,
  errorMessage: null,
  signinError: null,
  user: {}
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
  case AUTH_USER:
    return {
      ...state,
      authenticated: true,
      user: action.payload
    };
  case UNAUTH_USER:
    return {};
  case AUTH_USER_REQUEST:
    return {
      ...state,
      isFetching: true,
      isError: false,
      errorMessage: null,
      signinError: null
    };
  case AUTH_USER_SUCCESS:
    return {
      ...state,
      authenticated: true,
      isFetching: false,
      signinError: null,
      user: action.payload
    };
  case AUTH_USER_FAILURE:
    return {
      ...state,
      authenticated: false,
      isFetching: false,
      isError: true,
      signinError: action.payload,
      user: {}
    };
  case ACTIVATE_MEMBERSHIP_REQUEST:
    return {
      ...state,
      isFetching: true,
      isError: false,
      errorMessage: null,
      signinError: null
    };
  case ACTIVATE_MEMBERSHIP_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    };
  case SIGNIN_USER_FORM_RESET:
    return {
      ...state,
      authenticated: false,
      isFetching: false,
      isError: false,
      errorMessage: null,
      signinError: null,
      user: {}
    };

  // case FORGOT_PASSWORD_FORM_RESET:
  //   return {
  //     isError: false,
  //     errorMessage: null,
  //   };
  // case FORGOT_PASSWORD_REQUEST:
  //   return {
  //     ...state,
  //     isFetching: true,
  //     isError: false,
  //     errorMessage: null
  //   };
  // case FORGOT_PASSWORD_SUCCESS:
  //   return {
  //     ...state,
  //     isFetching: false
  //   };
  // case FORGOT_PASSWORD_FAILURE:
  //   return {
  //     ...state,
  //     isFetching: false,
  //     isError: true,
  //     errorMessage: action.payload
  //   };
  // case RESET_PASSWORD_REQUEST:
  //   return {
  //     ...state,
  //     isFetching: true,
  //     isError: false,
  //     errorMessage: null
  //   };
  // case RESET_PASSWORD_FAILURE:
  //   return {
  //     ...state,
  //     isFetching: false,
  //     isError: true,
  //     errorMessage: action.payload
  //   };    
  default:
    return state;
  }
}
