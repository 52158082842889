import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import swReducer from './sw/reducers/sw';
import uiReducer from './ui/reducers/ui';
import navigationReducer from './ui/reducers/navigation';
import locationReducer from './locations/reducer/location';
import courseReducer from './courses/reducers/course';
import batchReducer from './courses/reducers/batch';
import conferenceReducer from './conferences/reducers/conference';
import webinarReducer from './conferences/reducers/webinar';
// import skillReducer from './skills/reducer/skill';
import authReducer from './auth/reducers/auth';
import signupReducer from './auth/reducers/signup';
import organisationReducer from './organisation/reducers/organisation';
import membersReducer from './members/reducers/member';
import dashboardReducer from './dashboard/reducers/dashboard';
// import settingsReducer from './settings/reducers/settings';
import userReducer from './user/reducers/user';
import passwordReducer from './auth/reducers/password';
import studentReducer from './students/reducers/students';
import collaboratorReducer from './collaborators/reducers/collaborator'

// import webinarReducer from './webinars/reducers/webinar';

const rootReducer = combineReducers({
  form: formReducer,
  sw: swReducer,
  ui: uiReducer,
  navigation: navigationReducer,
  auth: authReducer,
  password: passwordReducer,
  location: locationReducer,
  course: courseReducer,
  batch: batchReducer,
  conference: conferenceReducer,
  // skill: skillReducer,
  signup: signupReducer,
  organisation: organisationReducer,
  members: membersReducer,
  dashboard: dashboardReducer,
  // settings: settingsReducer,
  user: userReducer,
  student: studentReducer,
  webinar: webinarReducer,
  collaborator: collaboratorReducer
});

export default rootReducer;
