import {
  FETCH_COURSES_REQUEST,
  FETCH_COURSES_SUCCESS,
  FETCH_COURSES_FAILURE,
  FETCH_COURSE_REQUEST,
  FETCH_COURSE_SUCCESS,
  FETCH_COURSE_FAILURE,
  CREATE_COURSE_REQUEST,
  CREATE_COURSE_SUCCESS,
  CREATE_COURSE_FAILURE,
  CREATE_BATCH_SUCCESS,
  UPDATE_COURSE_REQUEST,
  UPDATE_COURSE_FAILURE
} from '../constants';

const INITIAL_STATE = {
  isFetching: false,
  isError: false,
  errorMessage: '',
  errorFields: {},
  byId: {},
  allIds: [],
  selected: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_COURSES_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: '',
        errorFields: {}
      };
    case FETCH_COURSES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        allIds: action.payload.allIds,
        byId: action.payload.byId
      };
    case FETCH_COURSES_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload.errorMessage
      };
    case FETCH_COURSE_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: '',
        errorFields: {}
      };
    case FETCH_COURSE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        selected: action.payload
      };
    case FETCH_COURSE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload.errorMessage
      };
    case CREATE_COURSE_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: '',
        errorFields: {}
      };
    case CREATE_COURSE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        allIds: [action.payload.id, ...state.allIds],
        byId: { ...state.byId, [`${action.payload.id}`]: action.payload },
        selected: action.payload
      };
    case CREATE_COURSE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload.errorMessage,
        errorFields: action.payload.errorFields
      };
    case CREATE_BATCH_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [`${action.payload.course.id}`]: action.payload.course },
        selected: action.payload.course
      };
    case UPDATE_COURSE_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: '',
        errorFields: {}
      };
    case UPDATE_COURSE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload.errorMessage,
        errorFields: action.payload.errorFields
      };
    default:
      return state;
  }
};
