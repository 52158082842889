import {
  SET_ONBOARDING_NAVLINKS,
  SET_ADMIN_NAVLINKS
} from "../constants";

const INITIAL_STATE = {
  links: [],
  currentLink: {},
  previousLink: {},
  nextLink: {},
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_ONBOARDING_NAVLINKS:
      return {
        ...state,
        links: action.payload.links,
        currentLink: action.payload.currentLink,
        previousLink: action.payload.previousLink,
        nextLink: action.payload.nextLink
      };
    case SET_ADMIN_NAVLINKS:
      return {
        ...state,
        links: action.payload.links,
        currentLink: action.payload.currentLink
      };
    default:
      return state;
  }
}
