import {
  FETCH_CONFERENCES_REQUEST,
  FETCH_CONFERENCES_SUCCESS,
  FETCH_CONFERENCES_FAILURE,
  FETCH_CONFERENCE_REQUEST,
  FETCH_CONFERENCE_SUCCESS,
  FETCH_CONFERENCE_FAILURE,
  FETCH_CONDUCTED_CONFERENCE_SUCCESS,
  CREATE_CONFERENCE_REQUEST,
  CREATE_CONFERENCE_SUCCESS,
  CREATE_CONFERENCE_FAILURE,
  UPDATE_CONFERENCE_REQUEST,
  UPDATE_CONFERENCE_SUCCESS,
  UPDATE_CONFERENCE_FAILURE,
  SET_CONF_SELECTED_DATE,
  SET_CONF_SELECTED_HOST,
  CLEAR_SELECTED_CONFERENCE,
  UPDATE_BULK_CONFERENCE_REQUEST,
  UPDATE_BULK_CONFERENCE_SUCCESS,
  UPDATE_BULK_CONFERENCE_FAILURE,
  SET_CONF_SELECTED_BATCH,
  SET_CONF_START_DATE,
  SET_CONF_END_DATE,
  SET_CONF_PAGINATION
} from '../constants';

const INITIAL_STATE = {
  isFetching: false,
  weekdays: [],
  ongoing: [],
  upcoming: [],
  conducted: [],
  conducted_count: 0,
  selected: {},
  selectedDate: null,
  selectedHost: null,
  selectedBatch: null,
  conductedSelected: {},
  isError: false,
  errorMessage: '',
  errorFields: {},
  confStartDate: null,
  confEndDate: null,
  page: 0,
  perPage: 10,
  searchTerm: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CONFERENCES_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: '',
        errorFields: {}
      };
    case FETCH_CONFERENCES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        weekdays: action.payload.weekdays,
        ongoing: action.payload.ongoing,
        upcoming: action.payload.upcoming,
        conducted: action.payload.conducted,
        conducted_count: action.payload.conducted_count
      };
    case FETCH_CONFERENCES_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload.errorMessage
      };
    case FETCH_CONFERENCE_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: ''
      };
    case FETCH_CONFERENCE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        selected: action.payload,
        conductedSelected: action.payload
      };
    case FETCH_CONFERENCE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload.errorMessage
      };
    case FETCH_CONDUCTED_CONFERENCE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        conductedSelected: action.payload
      };
    case CREATE_CONFERENCE_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: '',
        errorFields: {}
      };
    case CREATE_CONFERENCE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        selected: action.payload
      };
    case CREATE_CONFERENCE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload.errorMessage,
        errorFields: action.payload.errorFields
      };
    case UPDATE_CONFERENCE_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: '',
        errorFields: {}
      };
    case UPDATE_CONFERENCE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        selected: action.payload,
        conductedSelected: action.payload
      };
    case UPDATE_CONFERENCE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload.errorMessage,
        errorFields: action.payload.errorFields
      };
    case SET_CONF_SELECTED_HOST:
      return {
        ...state,
        selectedHost: action.payload
      };
    case SET_CONF_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.payload
      };
    case SET_CONF_SELECTED_BATCH:
      return {
        ...state,
        selectedBatch: action.payload
      };
    case CLEAR_SELECTED_CONFERENCE:
      return {
        ...state,
        selected: action.payload
      };
    case UPDATE_BULK_CONFERENCE_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: '',
        errorFields: {}
      };
    case UPDATE_BULK_CONFERENCE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        selected: {},
        conductedSelected: {}

      };
    case UPDATE_BULK_CONFERENCE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    case SET_CONF_START_DATE:
      return {
        ...state,
        confStartDate: action.payload
      };
    case SET_CONF_END_DATE:
      return {
        ...state,
        confEndDate: action.payload
      };
    case SET_CONF_PAGINATION:
      return {
        ...state,
        page: action.payload.page,
        perPage: action.payload.perPage,
        searchTerm: action.payload.searchTerm
      };
    default:
      return state;
  }
};
