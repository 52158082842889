export const INVITE_COLLABORATORS_REQUEST = 'invite_collaborators_request';
export const INVITE_COLLABORATORS_SUCCESS = 'invite_collaborators_success';
export const INVITE_COLLABORATORS_FAILURE = 'invite_collaborators_failure';

export const FETCH_COLLABORATOR_REQUEST = 'fetch_collaborator_request';
export const FETCH_COLLABORATOR_SUCCESS = 'fetch_collaborator_success';
export const FETCH_COLLABORATOR_FAILURE = 'fetch_collaborator_failure';

// export const MEMBER_DETAILS_FETCH = 'member_details_fetch';
// export const MEMBER_DETAILS_SUCCESS = 'member_details_success';
// export const MEMBER_DETAILS_FAILURE = 'member_details_failure';

export const UPDATE_COLLABORATOR_ROLE_REQUEST = 'update_collaborator_role_request';
export const UPDATE_COLLABORATOR_ROLE_SUCCESS = 'update_collaborator_role_success';
export const UPDATE_COLLABORATOR_ROLE_FAILURE = 'update_collaborator_role_failure';

export const UPDATE_COLLABORATOR_REQUEST = 'update_collaborator_request';
export const UPDATE_COLLABORATOR_SUCCESS = 'update_collaborator_success';
export const UPDATE_COLLABORATOR_FAILURE = 'update_collaborator_failure';

export const REMOVE_COLLABORATOR_REQUEST = 'delete_collaborator_request';
export const REMOVE_COLLABORATOR_SUCCESS = 'delete_collaborator_success';
export const REMOVE_COLLABORATOR_FAILURE = 'delete_collaborator_failure';

export const REINVITE_COLLABORATOR_REQUEST = 'reinvite_collaborator_request';
export const REINVITE_COLLABORATOR_SUCCESS = 'reinvite_collaborator_success';
export const REINVITE_COLLABORATOR_FAILURE = 'reinvite_collaborator_failure';

export const RESET_COLLABORATOR = 'reset_collaborator';
export const CLEAR_UPDATE_ERROR = 'clear_update_error';

export const successMessages = [
  "Great!! we've saved your input.",
  'Yaay!! data saved successfully.',
  "Success!! we've saved it properly."
];
