import {
  INVITE_MEMBERS_REQUEST,
  INVITE_MEMBERS_SUCCESS,
  INVITE_MEMBERS_FAILURE,
  FETCH_PEOPLE_REQUEST,
  FETCH_PEOPLE_SUCCESS,
  FETCH_PEOPLE_FAILURE,
  MEMBER_DETAILS_FETCH,
  MEMBER_DETAILS_SUCCESS,
  MEMBER_DETAILS_FAILURE,
  UPDATE_MEMBER_ROLE_REQUEST,
  UPDATE_MEMBER_ROLE_SUCCESS,
  UPDATE_MEMBER_ROLE_FAILURE,
  REMOVE_MEMBERSHIP_REQUEST,
  REMOVE_MEMBERSHIP_SUCCESS,
  REMOVE_MEMBERSHIP_FAILURE,
  CLEAR_UPDATE_ERROR,
  RESET_MEMBER
} from '../constants';

const INITIAL_STATE = {
  bulkInvitationSent: null,
  isFetching: false,
  isError: false,
  errorMessage: null,
  errorStatus: null,
  peopleList: [],
  peopleCount: 0,
  member: null,
  page: 0,
  perPage: 10,
  invalid_emails: [],
  invited_members: [],
  existing_members: [],
  with_another_role: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case INVITE_MEMBERS_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case INVITE_MEMBERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        bulkInvitationSent: true,
        invalid_emails: action.payload.invalid_emails,
        invited_members: action.payload.invited_members,
        existing_members: action.payload.existing_members,
        with_another_role: action.payload.with_another_role
      };
    case INVITE_MEMBERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    case FETCH_PEOPLE_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: ''
      };
    case FETCH_PEOPLE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        peopleList: action.payload.data,
        peopleCount: action.payload.count
      };
    case FETCH_PEOPLE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    case MEMBER_DETAILS_FETCH:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: '',
        errorStatus: null,
        member: null
      };
    case MEMBER_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        member: action.payload
      };
    case MEMBER_DETAILS_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload.errorMessage,
        errorStatus: action.payload.errorStatus
      };
    case UPDATE_MEMBER_ROLE_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: ''
      };
    case UPDATE_MEMBER_ROLE_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case UPDATE_MEMBER_ROLE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    case REMOVE_MEMBERSHIP_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: ''
      };
    case REMOVE_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case REMOVE_MEMBERSHIP_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    case RESET_MEMBER:
      return {
        ...state
        // peopleList: [],
        // peopleCount: 0,
      };
    case CLEAR_UPDATE_ERROR:
      return {
        ...state,
        isUpdateError: false,
        updateErrorMessage: null,
        isSkillError: false,
        skillErrorMessage: null,
        isError: false,
        errorMessage: ''
      };
    default:
      return state;
  }
}
