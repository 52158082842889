import {
    FETCH_ATTENDENCE_REQUEST,
    FETCH_ATTENDENCE_SUCCESS,
    FETCH_ATTENDENCE_FAILURE,
    FETCH_ACTIVITY_REQUEST,
    FETCH_ACTIVITY_SUCCESS,
    FETCH_ACTIVITY_FAILURE

} from '../constants';

const INITIAL_STATE = {
    isFetchingAttendence: false,
    isAttendenceError: false,
    attendenceErrorMessage: null,
    attendenceList: [],
    isFetchingActivity: false,
    isActivityError: false,
    activityErrorMessage: null,
    activityList: null,
    conference_count: 0
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case FETCH_ATTENDENCE_REQUEST:
            return {
                ...state,
                isFetchingAttendence: true,
                isAttendenceError: false,
                attendenceErrorMessage: null,
                attendenceList: null,
            }
        case FETCH_ATTENDENCE_SUCCESS:
            return {
                ...state,
                isFetchingAttendence: false,
                attendenceList: action.payload.attendenceList,
                conference_count: action.payload.conference_count
            }
        case FETCH_ATTENDENCE_FAILURE:
            return {
                ...state,
                isFetchingAttendence: false,
                isAttendenceError: true,
                attendenceErrorMessage: action.payload
            }
        case FETCH_ACTIVITY_REQUEST:
            return {
                ...state,
                isFetchingActivity: true,
                isActivityError: false,
                activityErrorMessage: null,
                activityList: null
            }
        case FETCH_ACTIVITY_SUCCESS:
            return {
                ...state,
                isFetchingActivity: false,
                activityList: action.payload
            }
        case FETCH_ACTIVITY_FAILURE:
            return {
                ...state,
                isFetchingActivity: false,
                isActivityError: true,
                activityErrorMessage: action.payload
            }
        default:
            return state;
    }
}