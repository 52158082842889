export const USER_FETCH_REQUEST = 'user_fetch_request';
export const USER_FETCH_SUCCESS = 'user_fetch_success';
export const USER_FETCH_FAILURE = 'user_fetch_failure';

export const USER_UPDATE_REQUEST = 'user_update_request';
export const USER_UPDATE_SUCCESS = 'user_update_success';
export const USER_UPDATE_FAILURE = 'user_update_failure';

export const IMAGE_UPDATE_REQUEST = 'image_update_request';
export const IMAGE_UPDATE_SUCCESS = 'image_update_success';
export const IMAGE_UPDATE_FAILURE = 'image_update_failure';


export const CHANGE_PASSWORD_REQUEST = 'change_password_request';
export const CHANGE_PASSWORD_FAILURE = 'change_password_failure';
export const RESET_CHANGE_PASSWORD_FORM = 'reset_change_password_form';
